import type { ReactNode } from "react";
import { createContext } from "react";
import { useAuth } from "react-oidc-context";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import fetchWithCredentials from "@/src/lib/fetchWithCredentials";

export const ApiServiceClientContext = createContext<ApolloClient<any> | null>(
    null
);

export const GitServiceClientContext = createContext<ApolloClient<any> | null>(
    null
);

export function GraphQLProvider({ children }: { children: ReactNode }) {
    const auth = useAuth();

    const uploadLink = createUploadLink({
        uri: import.meta.env.VITE_API_GRAPHQL_ENDPOINT,
        fetch: (uri, options) =>
            fetchWithCredentials({
                accessToken: auth.user?.access_token,
                options,
                uri,
            }),
    });

    const client = new ApolloClient({
        link: uploadLink,
        cache: new InMemoryCache(),
    });

    const gitServiceUploadLink = createUploadLink({
        uri: import.meta.env.VITE_GITSERVICE_GRAPHQL_ENDPOINT,
        fetch: (uri, options) =>
            fetchWithCredentials({
                accessToken: auth.user?.access_token,
                options,
                uri,
            }),
    });

    const gitServiceClient = new ApolloClient({
        link: gitServiceUploadLink,
        cache: new InMemoryCache(),
    });

    return (
        <ApolloProvider client={client}>
            <ApiServiceClientContext.Provider value={client}>
                <GitServiceClientContext.Provider value={gitServiceClient}>
                    {children}
                </GitServiceClientContext.Provider>
            </ApiServiceClientContext.Provider>
        </ApolloProvider>
    );
}

export default GraphQLProvider;
