import { withAuthenticationRequired } from "react-oidc-context";
import DashboardRoot from "@/src/components/templates/Dashboard/Dashboard";
import ErrorRoot from "@/src/components/templates/Error/Error";
import CreateValidatedQuestion from "@/src/components/pages/CreateValidatedQuestion";
import ValidatedQuestionsBySubjectDidOverview from "@/src/components/pages/ValidatedQuestionsBySubjectDidOverview";
import CreateHealthcareProviderExcerpt from "@/src/components/pages/CreateHealthcareProviderExcerpt";
import HealthcareProviderExcerptOverview from "@/src/components/pages/HealthcareProviderExcerptOverview";
import SubjectDIDsOverview from "@/src/components/pages/SubjectDIDsOverview";
import UsersOverview from "@/src/components/pages/UsersOverview";
import CreateUser from "@/src/components/pages/CreateUser";
import CreateSubjectDID from "@/src/components/pages/CreateSubjectDID";
import EditSubjectDID from "@/src/components/pages/EditSubjectDID";
import {
    createRootRoute,
    createRoute,
    createRouter,
    Navigate,
} from "@tanstack/react-router";
import Devtools from "./Devtools";

const rootRoute = createRootRoute({
    component: function Root() {
        const Dashboard = withAuthenticationRequired(DashboardRoot);

        return (
            <>
                <Dashboard />
                <Devtools />
            </>
        );
    },
});

const slashRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/",
    component: function Slash() {
        return <Navigate to="/validated-questions" />;
    },
});

const validatedQuestionsRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/validated-questions",
    component: ValidatedQuestionsBySubjectDidOverview,
});

const newValidatedQuestionRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/validated-questions/new",
    component: CreateValidatedQuestion,
});

const healthcareProviderExcerptsRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/healthcare-provider-excerpts",
    component: HealthcareProviderExcerptOverview,
});

const newHealthcareProviderExcerptRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/healthcare-provider-excerpts/new",
    component: CreateHealthcareProviderExcerpt,
});

const subjectDIDsRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/subject-dids",
    component: SubjectDIDsOverview,
});

const newSubjectDIDRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/subject-dids/new",
    component: CreateSubjectDID,
});

export const editSubjectDIDRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/subject-dids/$id",
    component: EditSubjectDID,
});

const usersRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/users",
    component: UsersOverview,
});

const newUserRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/users/new",
    component: CreateUser,
});

const starRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "*",
    component: ErrorRoot,
});

const routeTree = rootRoute.addChildren([
    slashRoute,
    validatedQuestionsRoute,
    newValidatedQuestionRoute,
    healthcareProviderExcerptsRoute,
    newHealthcareProviderExcerptRoute,
    subjectDIDsRoute,
    newSubjectDIDRoute,
    editSubjectDIDRoute,
    usersRoute,
    newUserRoute,
    starRoute,
]);

export const router = createRouter({ routeTree });

declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}
