/**
 * @fileOverview Devtools.tsx
 *
 * This file is a lazy-loaded component that will only be loaded when the
 * environment variable VITE_TANSTACK_ROUTER_DEVTOOLS is set to something truthy.
 */

import { lazy, Suspense } from "react";

const Devtools = !import.meta.env.VITE_TANSTACK_ROUTER_DEVTOOLS
    ? () => null
    : lazy(() =>
          import("@tanstack/router-devtools").then((res) => ({
              default: res.TanStackRouterDevtools,
          }))
      );

export default function TanStackRouterDevtools() {
    return (
        <Suspense>
            <Devtools />
        </Suspense>
    );
}
