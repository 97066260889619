import { styled } from "@mui/system";
import Box from "@mui/material/Box";

const ResultsBox = styled(Box)`
    margin-bottom: 1rem;
`;

export const SuccessBox = styled(ResultsBox)``;

export const ErrorResultStyling = styled(ResultsBox)`
    color: ${({ theme }) => theme.palette.error.main};
`;
