import { createContext, ReactNode, useContext } from "react";
import { FormProvider } from "react-hook-form";
import { useCreateHealthcareProviderExcerpt } from "./CreateHealthcareProviderExcerptHook";

const context = createContext(
    {} as ReturnType<typeof useCreateHealthcareProviderExcerpt>
);

export const CreateHealthcareProviderExcerptProvider = ({
    value,
    children,
}: {
    value: ReturnType<typeof useCreateHealthcareProviderExcerpt>;
    children: ReactNode;
}) => (
    <FormProvider {...value.form}>
        <context.Provider value={value}>{children}</context.Provider>
    </FormProvider>
);

export const useValidationQuestionProvider = () => useContext(context);
