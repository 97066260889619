import { useTranslation } from "react-i18next";
import DateTimePicker from "@/src/components/atoms/DateTimePicker/DateTimePicker";
import { Typography } from "@mui/material";
import FieldPaper from "src/components/organisms/FieldPaper/FieldPaper";
import { format, setMinutes } from "date-fns";
import { useFormContext, Controller } from "react-hook-form";
import { useEffect } from "react";

function DateComponent({ step = 999 }) {
    const { t } = useTranslation();

    const { control, setValue } = useFormContext();

    useEffect(() => {
        setValue("issueAt", formatDate(new Date()));
    }, []);

    return (
        <FieldPaper
            title={
                <Typography variant="h6">
                    {step}. {t("validated-question-create.date.title")}
                </Typography>
            }
        >
            <Controller
                name="issuedAt"
                control={control}
                render={({ field }) => {
                    return (
                        <DateTimePicker
                            views={["year", "month", "day", "hours"]}
                            defaultValue={new Date()}
                            onChange={(date) => {
                                if (isDate(date)) {
                                    field.onChange(formatDate(date));
                                }
                            }}
                        />
                    );
                }}
            />
        </FieldPaper>
    );
}

function isDate(input: unknown): input is Date {
    return input instanceof Date;
}

function formatDate(date: Date) {
    return format(setMinutes(date, 0), "yyyy-MM-dd'T'HH:mm");
}

export default DateComponent;
