import { useTranslation } from "react-i18next";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import InfoBlock from "@/src/components/atoms/InfoBlock/InfoBlock";
import CodeBlock from "@/src/components/organisms/CodeBlock/CodeBlock";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import { Close } from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export interface IndicatorDetailsProps {
    open?: boolean;
    onClose?: () => void;
    name?: string;
    indicator: {
        ontologies: { title: string }[];
        queryDescription: string;
        profileUrl: string;
        sparql: string;
    };
}

export function IndicatorDetails({
    open = false,
    name = "",
    onClose,
    indicator,
}: IndicatorDetailsProps) {
    const { t } = useTranslation();

    const infoBlockTitle = indicator.ontologies
        .map((ontology) => ontology.title)
        .join(", ");

    const handleClose = () => {
        onClose?.();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            sx={{ isolation: "isolate" }}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                    p: 4,
                    py: 2,
                    position: "absolute",
                    left: 0,
                    right: 0,
                    borderBottom: "1px solid silver",
                    background: "white",
                    zIndex: 100,
                }}
            >
                <Typography variant="h1" component="div">
                    {name}
                </Typography>
                <IconButton sx={{ ml: "auto" }} onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
                <Box px={4} pt={4} data-testid="title-push">
                    <Typography variant="h1" component="div">
                        {name}
                    </Typography>
                </Box>
                <Stack spacing={2} px={4} pt={6}>
                    <InfoBlock
                        label={t("info-box.ontology")}
                        value={infoBlockTitle}
                    />
                    <InfoBlock
                        label={t("info-box.description")}
                        value={indicator.queryDescription}
                    />
                    <a
                        href={indicator.profileUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t("info-box.publication-profile")}
                    </a>

                    <div>
                        <Typography>
                            <strong>{t("info-box.sparql")}</strong>
                        </Typography>
                        <CodeBlock inline={false} language={"sparql"}>
                            {indicator.sparql}
                        </CodeBlock>
                    </div>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ paddingX: 4, paddingY: 2 }}>
                <Button variant="contained" onClick={handleClose}>
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default IndicatorDetails;
