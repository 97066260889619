import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import { useGetSubjectDidsQuery } from "src/graphql/generated/api/graphql";
import FieldPaper from "src/components/organisms/FieldPaper/FieldPaper";
import getNameById from "./getNameById";

export interface SubjectPropTypes {
    step: number;
}

const Subject = ({ step }: SubjectPropTypes) => {
    const {
        register,
        formState: { errors },
        getValues,
        watch,
    } = useFormContext();

    const { t } = useTranslation();

    const { data: subjects } = useGetSubjectDidsQuery();

    const subjectDids = subjects?.getSubjectDids || [];

    const getName = getNameById(subjectDids);

    const selectedSubjectDids = getValues("subjectDids") || [];

    const isChecked = (id: string) => selectedSubjectDids.includes(id);

    const list = useMemo(
        () =>
            subjectDids.map((subjectDid) => ({
                id: subjectDid.id,
                name: subjectDid.name,
                isChecked: isChecked(subjectDid.id),
            })),
        [subjectDids, selectedSubjectDids]
    );
    //  Rerenders the react hook form the check the checks
    watch("subjectDids");

    return (
        <FieldPaper
            title={
                <Typography variant="h6">
                    {step}. {t("validated-question-create.subject.title")}
                </Typography>
            }
        >
            <FormControl fullWidth error={Boolean(errors["subjectDid"])}>
                <Select
                    data-testid="subject"
                    defaultValue={[]}
                    size="small"
                    multiple={true}
                    {...register("subjectDids")}
                    renderValue={(values) => values.map(getName).join(", ")}
                >
                    {list.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                            <Checkbox checked={item.isChecked} />
                            <ListItemText primary={item.name} />
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                    <ErrorMessage errors={errors} name="subjectDids" />
                </FormHelperText>
            </FormControl>
        </FieldPaper>
    );
};

export default Subject;
