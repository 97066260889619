import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export type ConfirmParameterSyncDialogProps = {
    open: boolean;
    parameters: { type: string }[];
    onClose: (v: any, reason?: string) => void;
};

export const ConfirmParameterSyncDialog = ({
    open,
    parameters,
    onClose,
}: ConfirmParameterSyncDialogProps) => {
    const { t } = useTranslation("common");

    const handleDeny = (dontAskAgain: boolean = false) => {
        onClose({ confirmed: false, dontAskAgain }, "deny");
    };

    const handleConfirm = () => {
        onClose({ confirmed: true, dontAskAgain: false }, "confirm");
    };

    const parametersJoined = useMemo(() => {
        return parameters
            .map((parameter) =>
                t(
                    `parameters.${parameter?.type.toLowerCase().replaceAll("_", "-")}`
                )
            )
            .join(", ")
            .toLowerCase();
    }, [parameters]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            data-testid="confirm-revoke-dialog"
            maxWidth="md"
        >
            <DialogTitle>{t("confirm-parameter.title")}</DialogTitle>
            <DialogContent>
                {t("confirm-parameter.description", {
                    parameterName: parametersJoined,
                    count: parameters.length,
                })}
                <Stack
                    direction="row"
                    sx={{
                        mt: 2,
                    }}
                    alignItems="center"
                ></Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    type="button"
                    variant="text"
                    color="secondary"
                    data-testid="cancel-button-dont-ask-again"
                    onClick={() => handleDeny(true)}
                >
                    {t("confirm-parameter.cancel-and-dont-ask-again")}
                </Button>
                <Button
                    type="button"
                    variant="text"
                    color="secondary"
                    data-testid="cancel-button"
                    onClick={() => handleDeny(false)}
                >
                    {t("confirm-parameter.cancel")}
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    data-testid="submit-button"
                    onClick={handleConfirm}
                >
                    {t("confirm-parameter.confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmParameterSyncDialog;
