import { Alert, Box, TableContainer, Typography } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import FieldPaper from "src/components/organisms/FieldPaper/FieldPaper";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import type { IndicatorsPropTypes } from "./Indicators.types";
import { useValidationQuestionProvider } from "../../context/CreateValidatedQuestionContext";
import { ErrorMessage } from "@hookform/error-message";

const Indicators = ({ step }: IndicatorsPropTypes) => {
    const validatedQuestions = useValidationQuestionProvider();
    const fieldName = "indicators";

    const {
        control,
        formState: { errors },
    } = useFormContext();

    const version = useWatch({
        control,
        name: "version",
    });

    const { t } = useTranslation();

    const columns = [
        {
            field: "name",
            headerName:
                t("validated-question-create.indicators.select-all") || "",
            width: 200,
        },
    ];

    const renderContent = () => {
        if (
            validatedQuestions.error &&
            validatedQuestions.error instanceof Error
        ) {
            return (
                <Alert severity="error">
                    {validatedQuestions.error.message}
                </Alert>
            );
        } else if (version) {
            if (
                validatedQuestions.indicators.length === 0 &&
                !validatedQuestions.loading
            ) {
                return (
                    <Alert severity="error">
                        {t("validated-question-create.indicators.no-results")}
                    </Alert>
                );
            } else {
                return (
                    <Box sx={{ m: -3 }}>
                        <TableContainer data-testid="indicator-table">
                            {errors["indicators"] && (
                                <Alert severity="error" sx={{ m: 2 }}>
                                    <ErrorMessage
                                        errors={errors}
                                        name="indicators"
                                    />
                                </Alert>
                            )}
                            <Controller
                                name={fieldName}
                                control={control}
                                render={({ field }) => (
                                    <DataGrid
                                        loading={validatedQuestions.loading}
                                        density="compact"
                                        rows={validatedQuestions.indicators}
                                        columns={columns}
                                        pageSizeOptions={[100]}
                                        getRowId={(row) => row.path}
                                        checkboxSelection
                                        rowSelectionModel={field?.value?.map(
                                            ({ path }: { path: string }) => path
                                        )}
                                        onRowSelectionModelChange={(
                                            selectedIndicators
                                        ) =>
                                            void field.onChange(
                                                validatedQuestions.indicators.filter(
                                                    (indicator) =>
                                                        selectedIndicators.includes(
                                                            indicator.path
                                                        )
                                                )
                                            )
                                        }
                                    />
                                )}
                            />
                        </TableContainer>
                    </Box>
                );
            }
        } else {
            return (
                <Alert severity="info">
                    {t("validated-question-create.indicators.empty-state")}
                </Alert>
            );
        }
    };

    return (
        <FieldPaper
            title={
                <Typography variant="h6">
                    {step}. {t("validated-question-create.indicators.title")}
                </Typography>
            }
        >
            {renderContent()}
        </FieldPaper>
    );
};

export default Indicators;
