import { type InfoBlockProps } from "./InfoBlock.types";
import Dl from "./components/Dl";
import Dt from "./components/Dt";
import Dd from "./components/Dd";

const InfoBlock = ({ label, value, testId }: InfoBlockProps) => (
    <Dl data-testid={testId}>
        <Dt>{label}</Dt>
        <Dd>{value}</Dd>
    </Dl>
);

export default InfoBlock;
