export default function castNumber(value: unknown): number {
    if (typeof value === "number") {
        return value;
    }
    if (typeof value === "string") {
        const parsed = parseFloat(value);
        return parsed;
    } else {
        return NaN;
    }
}
