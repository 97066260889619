import { useState } from "react";
import { ApolloError } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const useCreateUser = () => {
    const { t } = useTranslation("common", {
        keyPrefix: "create-user",
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<ApolloError>();

    const schema = yup
        .object({
            user: yup.object().shape({
                email: yup.string().required().email().label(t("email")),
                firstName: yup.string().required().label(t("first-name")),
                lastName: yup.string().required().label(t("last-name")),
                password: yup.string().min(8).required().label(t("password")),
            }),
        })
        .required();

    const form = useForm<yup.InferType<typeof schema>>({
        resolver: yupResolver(schema),
        reValidateMode: "onChange",
        mode: "onChange",
    });

    return {
        form,
        loading,
        error,
    };
};
