import type { ReactNode } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { nlNL as reactMuiOverheidTheme } from "@tribe/react-mui-overheid-thema";
import "@tribe/react-mui-overheid-thema/styles/style.scss";

export function ThemeProvider(props: { children: ReactNode }) {
    return (
        <MuiThemeProvider theme={reactMuiOverheidTheme}>
            <CssBaseline />
            {props.children}
        </MuiThemeProvider>
    );
}

export default ThemeProvider;
