import { z } from "zod";
import { parameterBaseSchema } from "./parameterBase.schema";
import { ParameterTypeEnum } from "./ParameterType.enum";

/**
 * Schema for reference date parameter
 */
export const referenceDateParameterSchema = parameterBaseSchema.merge(
    z.object({
        type: z.literal(ParameterTypeEnum.REFERENCE_DATE),
    })
);
