import { useTranslation } from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import type { ResultsDialogProps } from "./RevokeResultsDialog.types";
import { useNavigate } from "@tanstack/react-router";
import type { FailureLocation } from "@/src/graphql/generated/api/graphql";

const ResultsDialog = ({
    open,
    results,
    onClose,
    navigateToPath = "/",
}: ResultsDialogProps) => {
    const { t } = useTranslation("common", {
        keyPrefix: "revoke-results-dialog",
    });
    const navigate = useNavigate();

    const handleCloseDialog = () => {
        onClose();
        void navigate({ to: navigateToPath });
    };
    const successResults = results?.filter(
        (result: { successful: boolean }) => result.successful
    );
    const scheduledResults = results?.filter(
        (result: { scheduled: boolean }) => result.scheduled
    );
    const unSuccessResults = results?.filter(
        (result: {
            unsuccessful?: {
                reasonPhrase: string;
                location: FailureLocation;
            } | null;
        }) => result.unsuccessful !== undefined && result.unsuccessful !== null
    );

    return (
        <Dialog
            onClose={handleCloseDialog}
            open={open}
            maxWidth={"sm"}
            fullWidth
            data-testid="result"
        >
            <DialogTitle>
                {scheduledResults?.length ? (
                    <Typography variant="subtitle1">
                        {t("title-revoked")}
                    </Typography>
                ) : (
                    <Typography variant="subtitle1">{t("title")}</Typography>
                )}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {scheduledResults?.length ? (
                        <Alert severity="success">
                            {t("amount-of-scheduled-revokes", {
                                amount: scheduledResults.length,
                            })}
                        </Alert>
                    ) : null}

                    {successResults?.length ? (
                        <Alert severity="success">
                            {t("amount-of-successful-revokes", {
                                amount: successResults.length,
                            })}
                        </Alert>
                    ) : null}
                    {unSuccessResults?.length ? (
                        <Alert severity="error">
                            {t("amount-of-unsuccessful-revokes", {
                                amount: unSuccessResults.length,
                            })}
                        </Alert>
                    ) : null}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>{t("ok")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ResultsDialog;
