import { Input, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { ErrorMessage } from "@hookform/error-message";
import { useCreateSubjectDID } from "@/src/components/templates/CreateSubjectDID/context/CreateSubjectDIDHook";
import { useState } from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { useCreateSubjectDidMutation } from "@/src/graphql/generated/api/graphql";
import { useNavigate } from "@tanstack/react-router";
import { useWatch } from "react-hook-form";

const CreateSubjectDIDTemplate = () => {
    const { t } = useTranslation("common", {
        keyPrefix: "create-subject-did",
    });

    const createSubjectDID = useCreateSubjectDID();
    const [loading, setLoading] = useState(false);

    const { form } = createSubjectDID;

    const subjectDid = useWatch({
        control: form.control,
        name: "subjectDid",
    });

    const navigate = useNavigate();

    const onSubmit = form.handleSubmit(
        () => {
            setLoading(true);
            createSubjectDidMutation();
        },
        (error) => {
            console.error(error);
        }
    );

    const [createSubjectDidMutation] = useCreateSubjectDidMutation({
        variables: {
            input: {
                name: subjectDid?.name,
                zinlDid: subjectDid?.zinlDid,
            },
        },
        refetchQueries: ["GetSubjectDids"],
        onCompleted: (_data) => {
            setTimeout(() => {
                setLoading(false);
                void navigate({ to: "/subject-dids" });
            }, 1000);
        },
        onError: (error) => {
            console.error(error);
            setLoading(false);
        },
    });

    return (
        <div>
            <form onSubmit={onSubmit} data-testid="form">
                <Stack direction="row" marginTop={3}>
                    <FormControl>
                        <InputLabel htmlFor="firstName">{t("name")}</InputLabel>
                        <Input
                            {...form.register("subjectDid.name")}
                            id="name"
                        />
                        <FormHelperText>
                            <ErrorMessage
                                errors={form.formState.errors}
                                name="subjectDid.name"
                            />
                        </FormHelperText>
                    </FormControl>
                </Stack>
                <Stack direction="row" marginTop={3}>
                    <FormControl>
                        <InputLabel htmlFor="firstName">
                            {t("zinlDid")}
                        </InputLabel>
                        <Input
                            {...form.register("subjectDid.zinlDid")}
                            id="zinlDid"
                        />
                        <FormHelperText>
                            <ErrorMessage
                                errors={form.formState.errors}
                                name="subjectDid.zinlDid"
                            />
                        </FormHelperText>
                    </FormControl>
                </Stack>
                <Stack direction="row" marginTop={3}>
                    <Box flex="0 1 auto" />
                    <LoadingButton
                        type="submit"
                        loading={loading}
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        disabled={!form.formState.isValid}
                        data-testid="submit-button"
                    >
                        {t("submit")}
                    </LoadingButton>
                </Stack>
            </form>
        </div>
    );
};

export default CreateSubjectDIDTemplate;
