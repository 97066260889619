import {
    useRevokeSubjectDidMutation,
    useGetSubjectDidsQuery,
} from "@/src/graphql/generated/api/graphql";
import PaginatedList from "@/src/components/organisms/PaginatedList/PaginatedList";
import { memo, type MouseEvent, useEffect, useState } from "react";
import { styled } from "./SubjectDIDsOverviewTemplate.styles";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "@/src/components/organisms/ConfirmDialog/ConfirmDialog";
import { useNavigate } from "@tanstack/react-router";
import { Alert } from "@mui/material";

type SubjectDid = {
    id: string;
    name: string;
    zinlDid: string;
};

const SubjectDIDsOverviewTemplate = () => {
    const { loading, subjectDids } = useSubjectDids();
    const [page] = useState(0);
    const count = subjectDids?.length;
    const [pageSize] = useState(count);

    const [toRevoke, setToDelete] = useState<SubjectDid | null>(null);
    const [confirmedRevoke, setConfirmedDeletion] = useState<SubjectDid | null>(
        null
    );

    const navigate = useNavigate();

    const { t } = useTranslation("common", {
        keyPrefix: "subject-dids-overview",
    });

    const [deleteSubjectDid, { error }] = useRevokeSubjectDidMutation({
        variables: {
            id: confirmedRevoke?.id ?? "",
        },
        refetchQueries: ["GetSubjectDids"],
        onCompleted: () => {
            setConfirmedDeletion(null);
        },
        onError: () => {
            setConfirmedDeletion(null);
        },
    });

    useEffect(() => {
        if (confirmedRevoke) {
            deleteSubjectDid().finally();
        }
    }, [confirmedRevoke]);

    const handleDeleteClick = (event: MouseEvent<HTMLButtonElement>) => {
        const id = event.currentTarget.dataset.itemId;

        const item = subjectDids?.find((item) => item.id == id) ?? null;

        setToDelete(item);
    };

    const handleRevokeConfirmClose = ({
        confirmed,
    }: {
        confirmed?: boolean | null;
    }) => {
        if (confirmed) setConfirmedDeletion(toRevoke);
        setToDelete(null);
    };

    const handleItemClick = (event: MouseEvent<HTMLDivElement>) => {
        const id = event.currentTarget.dataset.itemId;

        void navigate({ to: `/subject-dids/${id}` });
    };

    return (
        <>
            <styled.Container>
                {error && (
                    <Alert severity="error">{t("could-not-be-deleted")}</Alert>
                )}

                <styled.Header
                    title={t("title")}
                    button={{
                        to: "new",
                        label: t("new"),
                    }}
                />
                <styled.Main>
                    <PaginatedList<any>
                        data={subjectDids}
                        loading={loading}
                        count={count}
                        page={page}
                        rowsPerPage={pageSize}
                        onDeleteClick={handleDeleteClick}
                        onItemClick={handleItemClick}
                    />
                </styled.Main>
            </styled.Container>
            <ConfirmDialog
                open={Boolean(toRevoke !== null)}
                indicators={[
                    { id: toRevoke?.id ?? "", name: toRevoke?.name ?? "" },
                ]}
                onClose={handleRevokeConfirmClose}
                translationPrefix="confirm-dialogs.subject-dids"
            />
        </>
    );
};

function useSubjectDids() {
    const { loading, data, refetch } = useGetSubjectDidsQuery({
        fetchPolicy: "network-only",
    });

    return {
        loading,
        refetch,
        subjectDids: data?.getSubjectDids.map((subjectDid) => ({
            id: subjectDid.id,
            name: `${subjectDid.name} - ${subjectDid.zinlDid}`,
            zinlDid: subjectDid.zinlDid,
        })),
    };
}

export default memo(SubjectDIDsOverviewTemplate);
