import { useContext, useEffect, useRef, useState } from "react";
import type {
    GetExchangeProfilesQuery,
    IndicatorInput,
} from "src/graphql/generated/api/graphql";
import { useGetExchangeProfilesQuery } from "src/graphql/generated/api/graphql";
import type { GetIndicatorsForRepoQuery } from "src/graphql/generated/gitservice/graphql";
import { GetIndicatorsForRepoDocument } from "src/graphql/generated/gitservice/graphql";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { GitServiceClientContext } from "@/src/providers/graphql";
import type { NotNull } from "yup";

export const useCreateValidatedQuestions = () => {
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState<unknown>();

    const [confirmed, setConfirmed] = useState<string[]>([]);

    const possibleKeysOfParameterValues = useRef<string[]>([]);

    const addPossibleKeyOfParameterValuesUnique = (...keys: string[]) => {
        keys.forEach((key) => {
            if (!possibleKeysOfParameterValues.current.includes(key)) {
                possibleKeysOfParameterValues.current.push(key);
            }
        });
    };

    const [currentExchangeProfile, setCurrentExchangeProfile] =
        useState<GetExchangeProfilesQuery["getExchangeProfiles"][number]>();

    const { data: exchangeProfiles } = useGetExchangeProfilesQuery();

    const [indicators, setIndicators] = useState<
        GetIndicatorsForRepoQuery["getIndicatorsForRepo"]
    >([]);

    const [parameters, setParameters] = useState<IndicatorInput[]>([]);

    const { t } = useTranslation("common", {
        keyPrefix: "validated-question-create",
    });

    const gitServiceClient = useContext(GitServiceClientContext);

    const schema = yup
        .object({
            subjectDids: yup
                .array()
                .of(yup.string())
                .required(t("subject.errors.required") ?? void 0)
                .min(1, t("subject.errors.required") ?? void 0),
            exchangeProfile: yup
                .string()
                .required(t("exchangeProfile.errors.required") ?? void 0),
            version: yup
                .string()
                .required(t("version.errors.required") ?? void 0),
            indicators: yup
                .array()
                .of(
                    yup.object().shape({
                        name: yup.string().required(),
                        issueAt: yup.string(),
                        parameters: yup.array().of(
                            yup.object().shape({
                                type: yup.string().required(),
                                path: yup.string().required(),
                                name: yup.string().required(),
                            })
                        ),
                    })
                )
                .min(1, t("indicators.errors.required") ?? void 0),
            issueAt: yup.string().required(),
        })
        .required();

    const form = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            version: "",
            exchangeProfile: "",
            indicators: [],
            subjectDids: [],
        },
    });

    const exchangeProfile = useWatch({
        control: form.control,
        name: "exchangeProfile",
    });

    const version = useWatch({
        control: form.control,
        name: "version",
    });

    useEffect(() => {
        const profile = exchangeProfiles?.getExchangeProfiles.find(
            (item) => item.id === exchangeProfile
        );

        setCurrentExchangeProfile(profile);
    }, [exchangeProfile]);

    useEffect(() => {
        if (!currentExchangeProfile || !version) return;
        setLoading(true);
        setError(undefined);

        const fetchData = async () => {
            if (!gitServiceClient)
                throw new Error("GitServiceClient is not set");

            try {
                const indicatorsForRepo =
                    await gitServiceClient.query<GetIndicatorsForRepoQuery>({
                        query: GetIndicatorsForRepoDocument,
                        variables: {
                            repositoryUrl: currentExchangeProfile.url,
                            branchOrTag: version,
                        },
                    });

                setIndicators((prevState) => {
                    return (
                        indicatorsForRepo.data.getIndicatorsForRepo || []
                    ).map((indicator) => {
                        const existingIndicator = prevState.find(
                            (prevIndicator) =>
                                prevIndicator.name === indicator.name
                        ) as
                            | (NotNull<typeof prevState>[number] & {
                                  parameters: any[]; // type doesn't matter here. just ensure we merge data
                              })
                            | undefined;

                        return {
                            ...indicator,
                            parameters: existingIndicator?.parameters,
                        };
                    });
                });
                return indicatorsForRepo;
            } catch (error) {
                setError(error);
                setIndicators([]);
                setParameters([]);
            } finally {
                setLoading(false);
            }
        };

        void fetchData();
    }, [exchangeProfile, version, exchangeProfiles]);

    const selectedIndicators = form.getValues("indicators") ?? [];
    const allConfirmed = selectedIndicators.every((indicator) =>
        confirmed.includes(indicator.name)
    );

    return {
        form,
        exchangeProfiles,
        indicators,
        parameters,
        loading,
        error,
        confirmed,
        setConfirmed,
        allConfirmed,
        profile: currentExchangeProfile,
        possibleKeysOfParameterValues,
        addPossibleKeyOfParameterValuesUnique,
    };
};
