import { z } from "zod";

/**
 * Base Schema for reference date parameter
 *
 * Common properties for all parameters.
 */
export const parameterBaseSchema = z.object({
    name: z.string(),
    path: z.string(),
});
