import type { ReactNode } from "react";
import { AuthProvider as ReactOidcContextAuthProvider } from "react-oidc-context";
import type { AuthProviderProps as ReactOidcContextAuthProviderProps } from "react-oidc-context";

const reactOidcConfig: ReactOidcContextAuthProviderProps = {
    authority: import.meta.env.VITE_OIDC_AUTHORITY,
    client_id: import.meta.env.VITE_OIDC_CLIENT_ID,
    redirect_uri: import.meta.env.VITE_OIDC_REDIRECT_URI || location.origin,
    post_logout_redirect_uri:
        import.meta.env.VITE_OIDC_REDIRECT_URI || location.origin,
    automaticSilentRenew: true,
    onSigninCallback() {
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        );
    },
};

export function AuthProvider({ children }: { children: ReactNode }) {
    return (
        <ReactOidcContextAuthProvider {...reactOidcConfig}>
            {children}
        </ReactOidcContextAuthProvider>
    );
}

export default AuthProvider;
