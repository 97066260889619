import { useState } from "react";
import { ApolloError } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const useCreateHealthcareProviderExcerpt = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<ApolloError>();
    const { t } = useTranslation("common", {
        keyPrefix: "healthcare-provider-excerpts-create",
    });

    const schema = yup
        .object({
            healthcareProvider: yup
                .object()
                .shape({
                    id: yup.string().required(),
                    name: yup.string().required(),
                    cocNumber: yup.string().required(),
                })
                .required(t("healthcareProvider.required") ?? void 0),
        })
        .required();

    const form = useForm<yup.InferType<typeof schema>>({
        resolver: yupResolver(schema),
        reValidateMode: "onChange",
        mode: "onChange",
    });

    return {
        form,
        loading,
        error,
    };
};
