import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

function highlight(text = "", highlight = "") {
    const matches = match(text, highlight, { insideWords: true });
    const parts = parse(text, matches);
    return parts;
}

export default highlight;
