import type { ChangeEvent, Key } from "react";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { omit } from "lodash";
import { useWatch } from "react-hook-form";
import { useValidationQuestionProvider } from "./context/CreateValidatedQuestionContext";
import { useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { useGetCredentialDetailsForIndicatorsQuery } from "src/graphql/generated/api/graphql";
import { uniquePropFrom } from "src/lib/uniquePropFrom/uniquePropFrom";
import InfoBlock from "src/components/atoms/InfoBlock/InfoBlock";
import Parameters from "./steps/Parameters/Parameters";
import IndicatorPopup from "@/src/components/organisms/IndicatorPopup/IndicatorPopup.component";
import ConfirmParameterSyncDialog from "../../organisms/ConfirmParameterSyncDialog/ConfirmParameterSyncDialog.component";
import { createPortal } from "react-dom";
import type { DialogProps } from "@mui/material";

export type DialogType = {
    parameters: { type: string }[];
    onClose: (
        _response:
            | DialogProps["onClose"]
            | { confirmed: boolean; checked: boolean },
        reason: "confirm" | "deny" | string | undefined
    ) => void;
};

function InfoBox() {
    const validatedQuestions = useValidationQuestionProvider(),
        { confirmed, setConfirmed } = validatedQuestions;

    const { setValue } = useFormContext();
    const [dialog, setDialog] = useState<DialogType | null>(null);
    const [dontAskAgain, setDontAskAgain] = useState<string[]>([]);

    // Track a list of expanded indicators
    const [expand, setExpand] = useState<string[]>([]);

    const [configuredParameters, setConfiguredParameters] = useState<string[]>(
        []
    );

    // Track the indicator for which the detailed info is opened
    const [detailOpened, setDetailOpened] = useState<Key | null>(null),
        isDetailOpened = (key: Key) => detailOpened === key,
        handleDetailOpen = (key: Key) => setDetailOpened(key),
        handleDetailClose = () => setDetailOpened(null);

    function isConfirmed(key: string) {
        return confirmed.includes(key);
    }

    function isExpanded(key: string) {
        return expand.includes(key);
    }

    const handleExpand = (key: string) => (_: any, checked: boolean) => {
        setExpand((prev) => {
            const newExpand = checked
                ? [...prev, key]
                : prev.filter((i) => i !== key);
            return [...new Set(newExpand)];
        });
    };

    const { profile } = validatedQuestions;

    const version = useWatch({
        control: validatedQuestions.form.control,
        name: "version",
    });

    const indicators = useWatch({
        control: validatedQuestions.form.control,
        name: "indicators",
    });

    const { t } = useTranslation();

    const { data: { detailsForIndicators } = {}, loading } =
        useGetCredentialDetailsForIndicatorsQuery({
            variables: {
                // @ts-expect-error `skip` ensures that value is not undefined
                repositoryUrl: profile?.url,
                // @ts-expect-error TODO: ensure that `indicators` have a type (form)
                indicators: (indicators ?? []).map((indicator) =>
                    omit(indicator, ["__typename", "parameters"])
                ),
                branchOrTag: version,
            },
            skip: !profile?.url || !indicators?.length || !version,
        });

    // Set the confirmed and expand state based on the response from the server
    //
    // An indicator without parameters is automatically confirmed and remains collapsed, indicators that have parameters
    // are expanded and should be confirmed by the user.
    useEffect(() => {
        if (!detailsForIndicators) return;

        const toExpand = detailsForIndicators
            .map((indicatorDetail) => {
                if (indicatorDetail.parameters?.length === 0) {
                    void setConfirmed((prev) => [
                        ...prev,
                        indicatorDetail.queryName,
                    ]);
                    return null;
                }

                return indicatorDetail.queryName;
            })
            .filter((elem) => elem != null);
        setExpand((prev) => [...prev, ...toExpand]);
    }, [detailsForIndicators]);

    // Make sure that the path/type/etc props are set for the parameters
    useEffect(() => {
        if (!detailsForIndicators) return;

        // merge the parameters from the details with the selected indicators
        indicators?.forEach((indicator) => {
            const indicatorDetail = detailsForIndicators.find(
                (detail) => detail.queryName === indicator.name
            );

            if (!indicatorDetail) return;

            const updatedIndicator = {
                ...indicator,
                parameters: indicatorDetail.parameters.map((parameter) =>
                    omit(parameter, [
                        ...Object.keys(parameter).filter((key) =>
                            key.startsWith("possible")
                        ),
                    ])
                ),
            };

            const index = indicators.findIndex(
                (i) => i.name === indicator.name
            );

            validatedQuestions.form.setValue(
                `indicators.${index}`,
                // @ts-expect-error - the overlap between parameters should suffice, but for some reason TS does not agree
                updatedIndicator
            );
        });
    }, [detailsForIndicators]);

    type Indicator = Exclude<typeof indicators, undefined>[number];

    function findIndicatorByName(name: string): Indicator | undefined {
        return indicators?.find((indicator) => indicator.name === name);
    }

    function findIndicatorsWithMatchingParameters(
        indicator: Indicator
    ): Indicator[] {
        return (
            indicators?.filter((possibleMatchingIndicator) => {
                const notTheSameParameter =
                    possibleMatchingIndicator.name !== indicator.name;
                const hasOverlappingParameters =
                    possibleMatchingIndicator.parameters?.some(
                        (possibleMatchingParameter) => {
                            const notDontAskAgain = !dontAskAgain.includes(
                                possibleMatchingParameter.type
                            );
                            const hasSameType = indicator.parameters?.some(
                                (parameter) =>
                                    possibleMatchingParameter.type ===
                                    parameter.type
                            );
                            return notDontAskAgain && hasSameType;
                        }
                    );
                const isNotConfirmed = !confirmed.includes(
                    possibleMatchingIndicator.name
                );

                return (
                    notTheSameParameter &&
                    hasOverlappingParameters &&
                    isNotConfirmed
                );
            }) ?? []
        );
    }

    const handleConfirm = (key: string) => {
        return (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            event.stopPropagation();

            // If an indicator is unconfirmed, no other actions are required for other indicators
            if (!checked) {
                doHandleUnconfirm(key);
                return;
            }

            const indicatorBeingConfirmed = findIndicatorByName(key);
            if (!indicatorBeingConfirmed) return;

            const indicatorsWithMatchingParameters =
                findIndicatorsWithMatchingParameters(indicatorBeingConfirmed);

            // Determine whether we should ask to copy parameter values to other indicators
            const shouldNotAsk = indicatorBeingConfirmed?.parameters?.every(
                (parameter) => {
                    dontAskAgain.includes(parameter.type);
                }
            );

            const shouldHandleOnlyThisIndicator =
                (indicators?.length ?? 0) === 1 ||
                shouldNotAsk ||
                (indicatorsWithMatchingParameters?.length ?? 0) <= 0;
            if (shouldHandleOnlyThisIndicator) {
                doHandleConfirm(indicatorBeingConfirmed, []);
            } else {
                // Helper to mark a parameter type as 'dont-ask-again'
                const handleDontAskAgain = () => {
                    setDontAskAgain((prev) => [
                        ...new Set([
                            ...prev,
                            ...(indicatorBeingConfirmed?.parameters ?? []).map(
                                (p) => p.type
                            ),
                        ]),
                    ]);
                };

                // Show a dialog which allows the user to pick the behavior for copying parameter values
                setDialog({
                    parameters:
                        indicatorBeingConfirmed?.parameters?.filter(
                            (param) => !dontAskAgain.includes(param.type)
                        ) ?? [],
                    onClose: (
                        response:
                            | DialogProps["onClose"]
                            | { confirmed: boolean; dontAskAgain?: boolean },
                        reason: "confirm" | "deny" | string | undefined
                    ) => {
                        setDialog(null);

                        switch (reason) {
                            case "confirm":
                                doHandleConfirm(
                                    indicatorBeingConfirmed,
                                    indicatorsWithMatchingParameters ?? []
                                );
                                break;
                            case "deny":
                                if (
                                    "dontAskAgain" in response! &&
                                    response.dontAskAgain
                                ) {
                                    handleDontAskAgain();
                                }
                                doHandleConfirm(indicatorBeingConfirmed, []);
                                break;
                        }
                    },
                });
            }
        };
    };

    const doHandleUnconfirm = (key: string) => {
        setConfirmed((prev) => prev.filter((i) => i !== key));
        setExpand((prev) => [...prev, key]);
    };

    const doHandleConfirm = (
        indicatorBeingConfirmed: Indicator,
        indicatorsWithMatchingParameters: Indicator[]
    ) => {
        const nowConfigured = [...configuredParameters];

        const indicatorsBeingConfirmed = [indicatorBeingConfirmed];

        const indicatorBeingConfirmedIndex =
            indicators?.indexOf(indicatorBeingConfirmed) ?? -1;
        if (indicatorBeingConfirmedIndex >= 0) {
            indicatorBeingConfirmed.parameters?.forEach((_, index) => {
                if (
                    !nowConfigured.includes(
                        `indicators[${indicatorBeingConfirmedIndex}].parameters[${index}]`
                    )
                ) {
                    nowConfigured.push(
                        `indicators[${indicatorBeingConfirmedIndex}].parameters[${index}]`
                    );
                }
            });
        }

        indicatorsWithMatchingParameters.forEach((matchingIndicator) => {
            const matchingIndicatorIndex =
                indicators?.indexOf(matchingIndicator) ?? -1;

            // Copy matching parameter values from the indicator being confirmed to matching indicators
            indicatorBeingConfirmed.parameters?.forEach((parameter) => {
                const matchingParameterIndex =
                    matchingIndicator.parameters?.findIndex(
                        (indicatorParameter) => {
                            return indicatorParameter.type === parameter.type;
                        }
                    ) ?? -1;

                if (
                    matchingIndicatorIndex >= 0 &&
                    matchingParameterIndex >= 0 &&
                    !dontAskAgain.includes(parameter.type)
                ) {
                    setValue(
                        `indicators[${matchingIndicatorIndex}].parameters[${matchingParameterIndex}]`,
                        parameter
                    );
                    nowConfigured.push(
                        `indicators[${matchingIndicatorIndex}].parameters[${matchingParameterIndex}]`
                    );
                }
            });

            // Check whether the indicator is complete, as in that case we can agree and collapse the indicator
            const isIndicatorComplete = matchingIndicator.parameters?.every(
                (_, index) => {
                    return nowConfigured.includes(
                        `indicators[${matchingIndicatorIndex}].parameters[${index}]`
                    );
                }
            );
            if (isIndicatorComplete) {
                indicatorsBeingConfirmed.push(matchingIndicator);
            }
        });

        // Handle confirming/collapsing the completed indicators
        setConfirmed((prev) => [
            ...prev,
            ...indicatorsBeingConfirmed.map((indicator) => indicator.name),
        ]);
        setExpand((prev) =>
            prev.filter(
                (i) =>
                    !indicatorsBeingConfirmed
                        .map((indicator) => indicator.name)
                        .includes(i)
            )
        );

        setConfiguredParameters(nowConfigured);
    };

    if (!detailsForIndicators?.length) return null;

    if (loading) return <CircularProgress />;

    return (
        <>
            <div>
                <Stack spacing={2}>
                    {profile?.name && (
                        <InfoBlock
                            label={t("info-box.exchange-profile")}
                            value={profile.name}
                        />
                    )}

                    {indicators && (
                        <InfoBlock
                            testId="issue-at-date"
                            label={t("info-box.date")}
                            value={
                                uniquePropFrom(
                                    indicators,
                                    ({ issueAt }) => issueAt
                                ).map((issueAtDate = "") =>
                                    issueAtDate.replace("T", " ")
                                )[0] || t("info-box.no-date")
                            }
                        />
                    )}
                    {version && (
                        <InfoBlock
                            label={t("info-box.version")}
                            value={version}
                        />
                    )}

                    {detailsForIndicators.length ? (
                        <Typography>
                            <strong>{t("info-box.indicators")}:</strong>
                        </Typography>
                    ) : null}

                    {detailsForIndicators?.map((indicatorDetail, index) => (
                        <Fragment key={index}>
                            <Accordion
                                onChange={handleExpand(
                                    indicatorDetail.indicator.name
                                )}
                                expanded={isExpanded(
                                    indicatorDetail.indicator.name
                                )}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        width={"100%"}
                                    >
                                        <Typography>
                                            {indicatorDetail.indicator?.name}
                                        </Typography>
                                        <FormGroup
                                            onClick={(e) => e.stopPropagation()}
                                            sx={{ paddingLeft: 2 }}
                                        >
                                            <FormControlLabel
                                                sx={{ marginRight: 1 }}
                                                control={
                                                    <Checkbox
                                                        checked={isConfirmed(
                                                            indicatorDetail
                                                                .indicator.name
                                                        )}
                                                        onChange={handleConfirm(
                                                            indicatorDetail
                                                                .indicator.name
                                                        )}
                                                        size="small"
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body2">
                                                        {t("info-box.confirm")}
                                                    </Typography>
                                                }
                                            />
                                        </FormGroup>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {indicatorDetail?.parameters?.length ? (
                                        <>
                                            <Typography>
                                                <strong>
                                                    {t("info-box.parameters")}
                                                </strong>
                                            </Typography>
                                            <Parameters
                                                indexOfIndicator={index}
                                                // @ts-expect-error TODO: ensure that `parameters` have the right type
                                                parameters={
                                                    indicatorDetail.parameters
                                                }
                                            />
                                        </>
                                    ) : null}
                                </AccordionDetails>

                                <AccordionActions>
                                    <Button
                                        startIcon={<OpenInFullIcon />}
                                        variant="contained"
                                        onClick={() =>
                                            handleDetailOpen(
                                                indicatorDetail.queryName
                                            )
                                        }
                                    >
                                        {t("info-box.details")}
                                    </Button>
                                </AccordionActions>
                            </Accordion>

                            <IndicatorPopup
                                indicator={indicatorDetail}
                                name={indicatorDetail.indicator?.name || ""}
                                open={isDetailOpened(indicatorDetail.queryName)}
                                onClose={handleDetailClose}
                            />
                        </Fragment>
                    ))}
                    <div />
                </Stack>
            </div>
            {!!dialog &&
                createPortal(
                    <ConfirmParameterSyncDialog
                        open={!!dialog}
                        parameters={dialog.parameters}
                        onClose={dialog?.onClose}
                    />,
                    document.body
                )}
        </>
    );
}

export default InfoBox;
