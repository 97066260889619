import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import InfoBlock from "src/components/atoms/InfoBlock/InfoBlock";
import { useCreateHealthcareProviderExcerpt } from "./context/CreateHealthcareProviderExcerptHook";
import { useFormContext } from "react-hook-form";

const InfoBox = () => {
    const { t } = useTranslation("common", {
        keyPrefix: "healthcare-provider-excerpts-create.info-box",
    });
    const context = useCreateHealthcareProviderExcerpt();
    const form = useFormContext();
    const healthCareProvider = form.watch("healthcareProvider");

    if (context.loading) return <CircularProgress />;

    return healthCareProvider ? (
        <div>
            <Stack spacing={2}>
                <Typography>{t("title")}</Typography>
                <InfoBlock
                    label={t("healthcare-provider.label")}
                    value={healthCareProvider?.name}
                />
                <InfoBlock
                    label={t("kvk-number.label")}
                    value={healthCareProvider?.cocNumber}
                />
            </Stack>
        </div>
    ) : null;
};

export default InfoBox;
