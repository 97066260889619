export async function fetchWithCredentials({
    accessToken,
    uri,
    options: _options,
}: {
    accessToken?: string;
    uri: URL | RequestInfo;
    options?: RequestInit;
}) {
    const options: RequestInit = {
        ...(_options || {}),
    };

    if (accessToken) {
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${accessToken}`,
        };
    }

    return fetch(uri, options);
}

export default fetchWithCredentials;
