import Highlight from "src/components/atoms/Highlight/Highlight";
import { type HighlightedListItemProps } from "./HighlightedListItem.types";

const HighlightedListItem = ({
    highlightProps,
    listItemProps,
}: HighlightedListItemProps) => (
    <li {...listItemProps}>
        <Highlight {...highlightProps} />
    </li>
);

export default HighlightedListItem;
