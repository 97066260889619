import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { setLocale } from "yup";
import { nl } from "yup-locales";
import { toTitleCase } from "./lib/toTitleCase/totTitleCase";
import { capitalizeFirst } from "./lib/capitalizeFirst/capitalizeFirst";

const I18NEXT_DEBUG = Boolean(import.meta.env.VITE_I18NEXT_DEBUG);

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        debug: I18NEXT_DEBUG,
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        lng: "nl",
        defaultNS: "common",
        interpolation: {
            escapeValue: false,
            format: function (value: string, format, lng) {
                switch (format) {
                    case "uppercase":
                        return value.toLocaleUpperCase();
                    case toTitleCase.name:
                        return toTitleCase(value);
                    case capitalizeFirst.name:
                        return capitalizeFirst(value);
                    default:
                        return value;
                }
            },
        },
    });

// Make sure that the language of the document is also updated to reflect the current language
// this tells the browser that the document is in a different language and should be treated as such
// avoiding issues with spellcheckers and other browser features
i18n.on("languageChanged", (lng) => {
    document.documentElement.lang = lng;
});

// Make sure that yup is also updated to reflect the current language
i18n.on("languageChanged", (lng) => {
    if (lng === "nl") setLocale(nl);
});

export default i18n;
