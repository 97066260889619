import { HTMLAttributes } from "react";
import Box from "@mui/material/Box";

const Dl = ({ children, ...otherProps }: HTMLAttributes<HTMLDListElement>) => (
    <Box
        component="dl"
        sx={{
            display: "flex",
            gap: "1ch",
            margin: 0,
            padding: 0,
        }}
        {...otherProps}
    >
        {children}
    </Box>
);

export default Dl;
