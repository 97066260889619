import { Box, Paper, Toolbar } from "@mui/material";
import { FieldPaperPropsType } from "./FieldPaper.types";

export const FieldPaper = ({ children, title }: FieldPaperPropsType) => {
    return (
        <Paper elevation={1}>
            <Paper component="header" elevation={0} color="primary">
                <Toolbar>{title}</Toolbar>
            </Paper>
            <Box sx={{ p: 3 }}>{children}</Box>
        </Paper>
    );
};

export default FieldPaper;
