import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import FieldPaper from "src/components/organisms/FieldPaper/FieldPaper";
import SelectHealthcareProviderAutocomplete from "src/components/organisms/SelectHealthcareProviderAutocomplete/SelectHealthcareProviderAutocomplete";

const SelectHealthcareProvider = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    return (
        <FieldPaper
            title={
                <Typography variant="h6">
                    {t(
                        "healthcare-provider-excerpts-create.healthcare-provider.title"
                    )}
                </Typography>
            }
        >
            <Controller
                name="healthcareProvider"
                control={control}
                render={({ field }) => (
                    <SelectHealthcareProviderAutocomplete
                        value={field.value}
                        onChange={field.onChange}
                    />
                )}
            />
        </FieldPaper>
    );
};

export default SelectHealthcareProvider;
