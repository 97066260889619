import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import { FormProvider } from "react-hook-form";
import type { useCreateValidatedQuestions } from "./CreateValidatedQuestionsHook";

const context = createContext(
    {} as ReturnType<typeof useCreateValidatedQuestions>
);

export const CreateValidationQuestionProvider = ({
    value,
    children,
}: {
    value: ReturnType<typeof useCreateValidatedQuestions>;
    children: ReactNode;
}) => (
    <FormProvider {...value.form}>
        <context.Provider value={value}>{children}</context.Provider>
    </FormProvider>
);

export const useValidationQuestionProvider = () => useContext(context);
