import { Box, styled } from "@mui/material";

const ResultsBox = styled(Box)`
    margin-bottom: 1rem;
`;

export const SuccessBox = styled(ResultsBox)``;

export const ErrorBox = styled(ResultsBox)`
    color: ${({ theme }) => theme.palette.error.main};
`;
