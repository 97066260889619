import { ReactNode } from "react";
import Typography from "@mui/material/Typography";

const Dt = ({ children }: { children: ReactNode }) => (
    <Typography component="dt" fontWeight="bold">
        {children}:
    </Typography>
);

export default Dt;
