import { type ComponentProps, type CSSProperties, type FormEvent } from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import noop from "@/src/lib/noop/noop";

// MUI dialogs are a bit weird, so we need to do some type acrobatics here
// to get the right types for the reason for closing the dialog.
// This allows to deal with the happy flow in the parent component. But it also
// allows to deal with certain default cases that are already provided by MUI.
type DialogProps = ComponentProps<typeof Dialog>;
type DialogOnCloseFn = Exclude<DialogProps["onClose"], undefined>;
type MuiDialogCloseReasons = Parameters<DialogOnCloseFn>[1];
type ConfirmRevokeCloseReasons = MuiDialogCloseReasons | "cancel" | "submit";

export default function ConfirmDialog({
    onOpen: _handleOpen = noop,
    // eslint-disable-next-line no-empty-pattern
    onClose: _handleClose = ({}: {
        event?: any;
        reason?: ConfirmRevokeCloseReasons;
        confirmed?: boolean;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
    }) => {},
    open = false,
    indicators = [] as { id: string; name: string }[],
    translationPrefix = null as null | string,
}) {
    const { t, i18n } = useTranslation("common", {
        keyPrefix: translationPrefix ?? "confirm-deletion",
    });

    const translateProps = {
        indicatorCount: indicators.length,
    };

    const schema = yup
        .object({
            input: yup
                .string()
                .required()
                .matches(new RegExp(`^${t("delete") ?? ""}$`, "i")),
        })
        .required();

    const form = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            input: "",
        },
    });

    const handleClose = (event: any, reason: ConfirmRevokeCloseReasons) => {
        form.setValue("input", "");
        _handleClose({ event, reason });
    };

    const handleClickOpen = () => {
        form.setValue("input", "");
        _handleOpen();
    };

    const handleCancel = () => {
        form.setValue("input", "");
        _handleClose({ reason: "cancel" });
    };

    const handleSubmit = () => {
        if (!form.formState.isValid) return;

        _handleClose({ reason: "submit", confirmed: true });
    };

    const handleFormSubmit = (e?: FormEvent<HTMLFormElement>) => {
        if (e) e.preventDefault();
    };

    useEffect(() => {
        if (open) handleClickOpen();
    }, [open]);

    const dialogTitle = i18n.format(
        t("confirm-delete", translateProps),
        "capitalizeFirst"
    );

    const dialogDescription = t("confirm-description", translateProps);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            data-testid="confirm-revoke-dialog"
        >
            <form onSubmit={handleFormSubmit}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogDescription}</DialogContentText>
                    <ul style={listStyleOverrides(indicators.length)}>
                        {indicators.map((indicator, index) => (
                            <li key={index}>{indicator.name}</li>
                        ))}
                    </ul>
                    <DialogContentText>{t("confirm-type")}</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        type="text"
                        fullWidth
                        placeholder={t("delete") ?? ""}
                        data-testid="input"
                        {...form.register("input")}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        variant="text"
                        color="secondary"
                        onClick={handleCancel}
                        data-testid="cancel-button"
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!form.formState.isValid}
                        data-testid="submit-button"
                    >
                        {t("confirm")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

function listStyleOverrides(indicatorsCount = 0) {
    const styles: CSSProperties = {};

    if (indicatorsCount <= 1) {
        styles.listStyle = "none";
        styles.paddingLeft = "0";
    }

    return styles;
}
