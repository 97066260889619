import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldPaper from "src/components/organisms/FieldPaper/FieldPaper";
import { useGetExchangeProfilesQuery } from "src/graphql/generated/api/graphql";
import { ExchangePropTypes } from "./ExchangeProfiles.types";

const ExchangeProfiles = ({ step }: ExchangePropTypes) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    const { data: exchangeProfiles } = useGetExchangeProfilesQuery();
    const { t } = useTranslation();

    return (
        <FieldPaper
            title={
                <Typography variant="h6" component="div">
                    {step}.{" "}
                    {t("validated-question-create.exchangeProfile.title")}
                </Typography>
            }
        >
            <FormControl fullWidth error={Boolean(errors["exchangeProfile"])}>
                <Select
                    data-testid="validated-questions-select"
                    defaultValue={""}
                    size="small"
                    {...register("exchangeProfile")}
                >
                    {exchangeProfiles?.getExchangeProfiles?.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                    <ErrorMessage errors={errors} name="exchangeProfile" />
                </FormHelperText>
            </FormControl>
        </FieldPaper>
    );
};

export default ExchangeProfiles;
