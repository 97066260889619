import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { type ComponentProps } from "react";
import nl from "date-fns/locale/nl";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import css from "./DateTimePicker.module.scss";

export type DateTimePickerProps = ComponentProps<typeof MuiDateTimePicker>;

export const DateTimePicker = ({
    views = ["year", "month", "day", "hours"],
    disablePast = false,
    onChange,
    defaultValue = new Date(),
}: DateTimePickerProps) => {
    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={nl}
            localeText={{
                fieldHoursPlaceholder() {
                    return "00:00";
                },
            }}
        >
            <MuiDateTimePicker
                className={css.MultiDatePicker}
                views={views}
                defaultValue={defaultValue}
                onChange={onChange}
                disablePast={disablePast}
                closeOnSelect={false}
                format="dd-MM-yyyy HH:00"
            />
        </LocalizationProvider>
    );
};

export default DateTimePicker;
