import { useUpdateSubjectDID } from "@/src/components/templates/EditSubjectDID/context/UpdateSubjectDID";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { Input, Stack } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import {
    useGetSubjectDidQuery,
    useUpdateSubjectDidMutation,
} from "@/src/graphql/generated/api/graphql";
import { useNavigate } from "@tanstack/react-router";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import { editSubjectDIDRoute } from "@/src/providers/router/router";
import { useWatch } from "react-hook-form";

const EditSubjectDIDTemplate = () => {
    const { t } = useTranslation("common", {
        keyPrefix: "update-subject-did",
    });

    const { id } = editSubjectDIDRoute.useParams();

    const { subjectDid } = useSubjectDid(id);

    const updateSubjectDID = useUpdateSubjectDID();
    const [loading, setLoading] = useState(false);

    const { form } = updateSubjectDID;

    const subjectDidForm = useWatch({
        control: form.control,
        name: "subjectDid",
    });

    useEffect(() => {
        if (subjectDid) {
            form.reset({ subjectDid: { name: subjectDid.name } });
        }
    }, [subjectDid]);

    const navigate = useNavigate();

    const onSubmit = form.handleSubmit(
        () => {
            setLoading(true);
            updateSubjectDidMutation();
        },
        (error) => {
            console.error(error);
        }
    );

    const [updateSubjectDidMutation] = useUpdateSubjectDidMutation({
        variables: {
            input: {
                id: id,
                name: subjectDidForm?.name,
            },
        },
        refetchQueries: ["GetSubjectDids"],
        onCompleted: (_data) => {
            setLoading(false);
            void navigate({ to: "/subject-dids" });
        },
        onError: (error) => {
            console.error(error);
            setLoading(false);
        },
    });

    return (
        <div>
            <form onSubmit={onSubmit} data-testid="form">
                <Stack direction="row" marginTop={3}>
                    <FormControl>
                        <InputLabel
                            shrink={Boolean(subjectDidForm?.name)}
                            htmlFor="firstName"
                        >
                            {t("name")}
                        </InputLabel>
                        <Input
                            {...form.register("subjectDid.name")}
                            id="name"
                        />
                    </FormControl>
                </Stack>
                <Stack direction="row" marginTop={3}>
                    <Box flex="0 1 auto" />
                    <LoadingButton
                        type="submit"
                        loading={loading}
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        disabled={!form.formState.isValid}
                        data-testid="submit-button"
                    >
                        {t("submit")}
                    </LoadingButton>
                </Stack>
            </form>
        </div>
    );
};

function useSubjectDid(id: string | undefined) {
    const { loading, data, refetch } = useGetSubjectDidQuery({
        variables: {
            id: id,
        },
    });

    return {
        loading,
        refetch,
        subjectDid: data?.getSubjectDid,
    };
}

export default EditSubjectDIDTemplate;
