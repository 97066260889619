import { Box } from "@mui/system";
import { type StickyContentProps } from "./StickyContent.types";

const StickyContent = ({ children, top = 80 }: StickyContentProps) => (
    <Box sx={{ position: "sticky", top }} data-testid="sticky-content">
        {children}
    </Box>
);

export default StickyContent;
