import type { ChangeEventHandler, MouseEventHandler, ReactNode } from "react";
import { Link } from "@tanstack/react-router";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { appBarHeight } from "src/lib/general";
import { Chip, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import noop from "@/src/lib/noop/noop";

type ContainerProps = {
    children: ReactNode;
};

type HeaderProps = {
    title: ReactNode;
    button: {
        to: string;
        label: ReactNode;
    };
};

type MainProps = {
    children: ReactNode;
};

type FooterProps = {
    visible: boolean;
    revokeButton: {
        label: ReactNode;
        onClick: MouseEventHandler<HTMLButtonElement>;
    };
    cancelSelectionButton: {
        label: ReactNode;
        onClick: MouseEventHandler<HTMLButtonElement>;
    };
};

type ExchangeProfileRowProps = {
    label: ReactNode;
    checkbox: {
        checked: boolean;
        indeterminate: boolean;
        onChange: ChangeEventHandler<HTMLInputElement>;
    };
    pl?: number;
    pt?: number;
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "";
};

type IndicatorRowGroupProps = {
    children: ReactNode;
};

type EchangeProfileDividerProps = {
    visible: boolean;
};

type IndicatorControlProps = {
    checked: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    label: ReactNode;
    button: {
        onClick: MouseEventHandler<HTMLButtonElement>;
        label: ReactNode;
    };
    status: string;
    version?: string | null;
};

export const styled = {
    Container({ children }: ContainerProps) {
        return (
            <Container component={"section"} maxWidth="md">
                <Stack spacing={4}>{children}</Stack>
            </Container>
        );
    },

    Header({ title, button }: HeaderProps) {
        return (
            <Box component={"header"} sx={{ pt: 1 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h1">{title}</Typography>
                    <Button
                        variant="contained"
                        size="large"
                        component={Link}
                        to={button.to}
                    >
                        {button.label}
                    </Button>
                </Stack>
            </Box>
        );
    },

    Main({ children }: MainProps) {
        return (
            <Stack
                component="main"
                direction="column"
                spacing={4}
                position="relative"
                minWidth="100%"
                minHeight="50vh"
            >
                {children}
            </Stack>
        );
    },

    /**
     * TODO: smooth animation when visible
     */
    Footer({ visible, revokeButton, cancelSelectionButton }: FooterProps) {
        return visible ? (
            <Box
                component="footer"
                sx={{
                    position: "sticky",
                    bottom: 0,
                    background: "#fff",
                    zIndex: 1,
                }}
            >
                <Divider />
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ p: 3 }}
                >
                    <Button
                        type="button"
                        variant="contained"
                        color="error"
                        startIcon={<DeleteForever />}
                        onClick={revokeButton.onClick}
                        data-testid="revoke-button"
                    >
                        {revokeButton.label}
                    </Button>
                    <Button
                        type="button"
                        variant="text"
                        color="secondary"
                        onClick={cancelSelectionButton.onClick}
                    >
                        {cancelSelectionButton.label}
                    </Button>
                </Stack>
            </Box>
        ) : null;
    },

    ExchangeProfileControl({
        label,
        checkbox,
        pl = 0,
        pt = 2,
        variant = "",
    }: ExchangeProfileRowProps) {
        return (
            <Box
                sx={{
                    position: "sticky",
                    top: appBarHeight,
                    left: 0,
                    right: 0,
                    background: "#fff",
                    zIndex: 1,
                    pt,
                    pl,
                }}
            >
                <FormControlLabel
                    label={
                        <strong data-testid="exchangeprofile-title">
                            <Typography
                                fontWeight={600}
                                variant={variant || undefined}
                            >
                                {label}
                            </Typography>
                        </strong>
                    }
                    control={
                        <Checkbox
                            checked={checkbox.checked}
                            indeterminate={
                                !checkbox.checked && checkbox.indeterminate
                            }
                            onChange={checkbox.onChange}
                        />
                    }
                />
            </Box>
        );
    },

    EchangeProfileDivider({ visible }: EchangeProfileDividerProps) {
        return visible ? <Divider sx={{ my: 4 }} /> : null;
    },

    IndicatorRowGroup({ children }: IndicatorRowGroupProps) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    ml: 6,
                }}
            >
                {children}
            </Box>
        );
    },

    ProfileRow({ children }: { children: ReactNode }) {
        return (
            <Box
                sx={{
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                }}
            >
                {children}
            </Box>
        );
    },

    ProfileVersion({ version }: { version: string }) {
        return <Chip label={version} size="medium" />;
    },

    IndicatorControl({
        checked = false,
        onChange: handleChange = noop,
        label = "",
        button,
        status = "",
        version = "",
    }: IndicatorControlProps) {
        const { t } = useTranslation();
        const versionLabel = version ? version : "";
        const statusLabel = status ? t(`status.${status.toLowerCase()}`) : "";
        const chips = [{ label: versionLabel }, { label: statusLabel }];
        const chipsWithLabel = chips.filter((chip) => Boolean(chip.label));

        return (
            <Stack direction="row" spacing={2}>
                <FormControlLabel
                    label={<span data-testid="indicator-title">{label}</span>}
                    control={
                        <Checkbox checked={checked} onChange={handleChange} />
                    }
                />
                <Box sx={{ flex: "1 1 auto" }} />
                <Stack direction="row" alignItems="center" gap={1}>
                    {chipsWithLabel.map((chip, index) => (
                        <Chip key={index} label={chip.label} size="small" />
                    ))}
                    <Button
                        type="button"
                        color="error"
                        sx={{
                            textTransform: "capitalize",
                            px: 1,
                            py: 0.5,
                        }}
                        startIcon={<DeleteForever />}
                        onClick={button.onClick}
                    >
                        {button.label}
                    </Button>
                </Stack>
            </Stack>
        );
    },

    Loading() {
        return (
            <Box
                sx={{
                    position: "absolute",
                    inset: 0,
                    background: "rgba(255,255,255,0.5)",
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CircularProgress />
            </Box>
        );
    },
};
