import { ReactNode, useState } from "react";
import styles from "./CodeBlock.module.scss";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula as SyntaxHighlighterStyle } from "react-syntax-highlighter/dist/cjs/styles/prism";
import copy from "copy-to-clipboard";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Typography } from "@mui/material";
import classNames from "classnames";

export const CodeBlock = ({
    inline = false,
    language,
    children,
    className,
}: {
    inline?: boolean;
    language?: string;
    children: ReactNode;
    className?: string;
}) => {
    const { t } = useTranslation();
    const [showCopied, setShowCopied] = useState(false);

    const handleCopy = () => {
        copy(String(children));
        setShowCopied(true);
        setTimeout(() => {
            setShowCopied(false);
        }, 5000);
    };

    return (
        <div className={styles.CodeBlock}>
            {!inline ? (
                <div className={styles.code}>
                    <div className={styles.copy} onClick={handleCopy}>
                        <Typography
                            className={classNames(styles.copyText, {
                                [styles.show]: showCopied,
                            })}
                        >
                            {t("general.copied")}
                        </Typography>
                        <ContentCopyIcon className={styles.copyIcon} />
                        <Typography className={styles.tooltip}>
                            {t("general.copy")}
                        </Typography>
                    </div>

                    <SyntaxHighlighter
                        showLineNumbers={true}
                        style={SyntaxHighlighterStyle as any}
                        language={language}
                    >
                        {String(children).replace(/\n$/, "")}
                    </SyntaxHighlighter>
                </div>
            ) : (
                <div className={styles.code}>
                    <code className={className}>{children}</code>
                </div>
            )}
        </div>
    );
};

export default CodeBlock;
