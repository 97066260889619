import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import {
    type SelectHealthcareProviderAutocompleteProps,
    type HealthcareProvider,
} from "./SelectHealthcareProviderAutocomplete.types";
import HighlightedListItem from "src/components/molecules/HighlightedListItem/HighlightedListItem";
import { useGetHealthcareProvidersQuery } from "@/src/graphql/generated/api/graphql";
import noop from "@/src/lib/noop/noop";

/**
 * Based on {@link https://mui.com/material-ui/react-autocomplete/#google-maps-place}
 */
const SelectHealthcareProviderAutocomplete = ({
    onChange = noop,
    onBlur = noop,
}: SelectHealthcareProviderAutocompleteProps) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<HealthcareProvider | null>(null);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<HealthcareProvider[]>([]);
    const { data, loading } = useGetHealthcareProvidersQuery();

    useEffect(() => {
        if (!data) {
            return;
        }
        setOptions(
            data.getHealthcareProviders.filter((x) =>
                x.name.toLowerCase().includes(inputValue.toLowerCase())
            )
        );
    }, [data, inputValue]);

    useEffect(() => {
        onChange(value);
    }, [value]);

    return (
        <Autocomplete
            loading={loading}
            onBlur={onBlur}
            fullWidth
            getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText={t("no-healthcare-provider-found")}
            onChange={(event: any, newValue: HealthcareProvider | null) => {
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("choose-healthcare-provider")}
                    fullWidth
                />
            )}
            renderOption={(props, option) => (
                <HighlightedListItem
                    key={option.id}
                    highlightProps={{
                        text: option.name,
                        highlight: inputValue,
                    }}
                    listItemProps={props}
                />
            )}
        />
    );
};

export default SelectHealthcareProviderAutocomplete;
