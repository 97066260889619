import type { Key } from "react";
import { useMemo, useState } from "react";
import type { RevokeCredentialsMutation } from "src/graphql/generated/api/graphql";
import {
    IssuedCredentialStatusEnum,
    Operator,
    useGetIssuedCredentialsBySubjectDidForOverviewQuery,
    useGetSubjectDidsQuery,
    useRevokeCredentialsMutation,
    VerifiableCredentialsFieldSelector,
} from "src/graphql/generated/api/graphql";
import Template from "../templates/ValidatedQuestionsBySubjectDidOverview/ValidatedQuestionsBySubjectDidOverview";
import mapValidatedQuestionsBySubjectDidByExchangeProfile from "@/src/lib/mapValidatedQuestionsBySubjectDidByExchangeProfile/mapValidatedQuestionsBySubjectDidByExchangeProfile";
import castNumber from "@/src/lib/castNumber/castNumber";

const ValidatedQuestionsBySubjectDidOverview = () => {
    const [revokeResults, setRevokeResults] =
        useState<
            RevokeCredentialsMutation["revokeVerifiableQueryCredentials"]
        >();

    const [openRevokeResultsDialog, setOpenRevokeResultsDialog] =
        useState(false);

    const [mutate] = useRevokeCredentialsMutation();

    const [filter, setFilter] = useState<Key[]>([]);

    const {
        loading,
        data: exchangeProfiles,
        refetch,
    } = useExchangeProfiles(filter.map(castNumber));
    const groupBySubjectDid =
        mapValidatedQuestionsBySubjectDidByExchangeProfile(exchangeProfiles);

    const handleRevoke = (indicators: number[], date: string | null) => {
        mutate({
            variables: {
                ids: indicators,
                revokeAt: date,
            },
        }).then((data) => {
            if (
                data.data?.revokeVerifiableQueryCredentials &&
                data.data?.revokeVerifiableQueryCredentials.length
            ) {
                setOpenRevokeResultsDialog(true);
                setRevokeResults(data.data?.revokeVerifiableQueryCredentials);
                refetch();
            }
        });
    };

    return (
        <Template
            loading={loading}
            subjectDids={groupBySubjectDid}
            indicators={
                exchangeProfiles?.getIssuedVerifiableQueryCredentials ?? []
            }
            onRevoke={(indicators, date) => {
                const indicatorIds = indicators.map(
                    (indicator) => indicator.id,
                    []
                );
                handleRevoke(indicatorIds, date);
            }}
            onFilter={setFilter}
            filter={filter}
            revokeResults={revokeResults}
            openRevokeResults={openRevokeResultsDialog}
            onCloseRevokeResults={() => setOpenRevokeResultsDialog(false)}
        />
    );
};

function useExchangeProfiles(subjectIds: number[] = []) {
    const { data: subjectDids } = useGetSubjectDidsQuery();

    const availableSubjectDids = subjectDids?.getSubjectDids || [];

    const defaultIds = useMemo(
        () =>
            availableSubjectDids.map((subjectDid) => {
                return subjectDid.id;
            }),
        [availableSubjectDids]
    );

    const subjectDidFilterInput: string = useMemo(() => {
        const filterInput =
            subjectIds.length > 0
                ? subjectIds.map(String)
                : defaultIds.map(String);

        return filterInput.toString();
    }, [subjectIds, defaultIds]);

    const statusses = Object.values(IssuedCredentialStatusEnum)
        .filter((status) => status !== IssuedCredentialStatusEnum.Revoked)
        .join(",");

    const { loading, data, refetch } =
        useGetIssuedCredentialsBySubjectDidForOverviewQuery({
            variables: {
                filters: [
                    {
                        selector: VerifiableCredentialsFieldSelector.SubjectId,
                        operator: Operator.In,
                        value: subjectDidFilterInput,
                    },
                    {
                        selector: VerifiableCredentialsFieldSelector.Status,
                        operator: Operator.In,
                        value: statusses,
                    },
                ],
            },
        });

    return {
        loading,
        refetch,
        data,
    };
}

export default ValidatedQuestionsBySubjectDidOverview;
