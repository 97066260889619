import AuthProvider from "./providers/auth";
import GraphQLProvider from "./providers/graphql";
import ThemeProvider from "./providers/theme";
import RouterProvider from "./providers/router";

function App() {
    return (
        <AuthProvider>
            <GraphQLProvider>
                <ThemeProvider>
                    <RouterProvider />
                </ThemeProvider>
            </GraphQLProvider>
        </AuthProvider>
    );
}

export default App;
