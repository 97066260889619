import type { useGetIssuedCredentialsBySubjectDidForOverviewQuery } from "@/src/graphql/generated/api/graphql";

export type Input = ReturnType<
    typeof useGetIssuedCredentialsBySubjectDidForOverviewQuery
>["data"];

export interface SubjectDidsForOverview {
    id: any;
    name: string;
    exchangeProfiles: ExchangeProfile[];
}

export interface ExchangeProfile {
    name: string;
    id?: string;
    indicators: Indicator[];
    version: string;
}

export interface Indicator {
    id: any;
    name: string;
    status: string;
    tagOrBranch?: string | null;
    parameter: [];
}

const splitByTagOrBranch = (arr: Indicator[]) => {
    return arr.reduce<Record<string, Indicator[]>>((acc, item) => {
        const key = item.tagOrBranch || "unkown";
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});
};

const groupByTagOrBranch = (exchangeProfile: ExchangeProfile) => {
    const tags = splitByTagOrBranch(exchangeProfile.indicators);
    const keys = Object.keys(tags);
    return [
        ...keys.map((key: string) => ({
            name: exchangeProfile.name,
            indicators: tags[key],
            version: key,
        })),
    ];
};

/**
 * Maps validated questions by subject DID and exchange profile.
 *
 * Basically we are transforming the data from the API into a more
 * structured format that is easier to work with in the UI.
 *
 *     - subjectDids
 *       - exchangeProfiles
 *         - indicators
 *
 * @param data - The input data containing the issued credentials.
 * @returns An array of subject DIDs with their associated exchange profiles and indicators.
 */
export default function mapValidatedQuestionsBySubjectDidByExchangeProfile(
    data: Input
) {
    const credentials = data?.getIssuedVerifiableQueryCredentials ?? [];
    const dids = credentials.reduce<SubjectDidsForOverview[]>(
        (acc, credential) => {
            let subjectDid = acc.find((x) => x.id === credential.subjectDid.id);
            if (subjectDid == null) {
                subjectDid = {
                    id: credential.subjectDid.id,
                    name: credential.subjectDid.name,
                    exchangeProfiles: [],
                };
                acc = [...acc, subjectDid];
            }

            let exchangeProfile = subjectDid.exchangeProfiles.find(
                (x) => x.name === credential.exchangeProfileName
            );

            if (exchangeProfile == null) {
                exchangeProfile = {
                    id: credential.id,
                    name: credential.exchangeProfileName,
                    indicators: [],
                    version: "null",
                };
                subjectDid.exchangeProfiles = [
                    ...subjectDid.exchangeProfiles,
                    exchangeProfile,
                ];
            }

            let indicator = exchangeProfile.indicators.find(
                (x) => x.id === credential.id
            );
            if (indicator == null) {
                indicator = {
                    id: credential.id,
                    name: credential.indicatorName,
                    status: credential.status,
                    tagOrBranch: credential.tagOrBranch,
                    parameter: [],
                };
                exchangeProfile.indicators = [
                    ...exchangeProfile.indicators,
                    indicator,
                ];
            }

            return acc;
        },
        []
    );

    return dids.map((d) => ({
        ...d,
        exchangeProfiles: d.exchangeProfiles.map((profile) =>
            groupByTagOrBranch(profile)
        ),
    }));
}
