import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import TablePagination from "@mui/material/TablePagination";
import { Icon } from "@tribe/react-mui-overheid-thema";
import Highlight from "src/components/atoms/Highlight/Highlight";
import { type PaginatedListProps } from "./PaginatedList.types";

const dense = true;
const skeletonItems = Array.from({ length: 5 }, () => Math.random());

const PaginatedList = <T extends { id: string; name: string }>({
    loading = false,
    data = [],
    query = "",
    count,
    page,
    onPageChange,
    onRowsPerPageChange,
    rowsPerPage = 10,
    onDeleteClick,
    onItemClick,
}: PaginatedListProps<T>) =>
    loading ? (
        <List dense={dense}>
            {skeletonItems.map((n) => (
                <Skeleton key={n} width="100%">
                    <ListItem
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete">
                                <Icon name="verwijderen" />
                            </IconButton>
                        }
                    >
                        <ListItemText primary={"Loading..."} />
                    </ListItem>
                </Skeleton>
            ))}
        </List>
    ) : (
        <>
            <List dense={dense}>
                {data.map((item) => (
                    <ListItem
                        key={item.id}
                        secondaryAction={
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={onDeleteClick}
                                data-item-id={item.id}
                            >
                                <Icon name="verwijderen" />
                            </IconButton>
                        }
                    >
                        <ListItemText
                            onClick={onItemClick}
                            data-item-id={item.id}
                            primary={
                                <Highlight
                                    text={item.name || ""}
                                    highlight={query}
                                />
                            }
                        />
                    </ListItem>
                ))}
            </List>
            {typeof page !== "undefined" &&
            rowsPerPage &&
            count &&
            onRowsPerPageChange &&
            onPageChange ? (
                <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                />
            ) : null}
        </>
    );

export default PaginatedList;
