import { z } from "zod";
import { dateRangeSchema } from "./dateRange.schema";
import { quarterAndYearSchema } from "./quarterAndYear.schema";
import { referenceDateParameterSchema } from "./referenceDateParameter.schema";

/**
 * Parameter union schema
 */
export const parameterSchema = z.union([
    referenceDateParameterSchema,
    quarterAndYearSchema,
    dateRangeSchema,
]);
