import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateHealthcareProviderExcerptProvider } from "./context/CreateHealthcareProviderExcerptContext";
import { useCreateHealthcareProviderExcerpt } from "./context/CreateHealthcareProviderExcerptHook";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import StickyContent from "src/components/atoms/StickyContent/StickyContent";
import FieldPaper from "src/components/organisms/FieldPaper/FieldPaper";
import SelectHealthcareProvider from "./steps/SelectHealthcareProvider/SelectHealthcareProvider";
import InfoBox from "./InfoBox";
import CreateHealthcareProviderExcerptResultDialog from "@/src/components/organisms/CreateHealthcareProviderExcerptResultDialog/CreateHealthcareProviderExcerptResultDialog";
import { useIssueCredentialsForHealthcareProvidersMutation } from "@/src/graphql/generated/api/graphql";

const CreateHealthcareProviderExcerpt = () => {
    const { t } = useTranslation();
    const [openResultsDialog, setOpenResultsDialog] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const createHealthcareProviderExcerpt =
        useCreateHealthcareProviderExcerpt();
    const { form } = createHealthcareProviderExcerpt;
    const healthcareProvider = form.watch("healthcareProvider");

    const [issueCrentialForHealthcareProvider] =
        useIssueCredentialsForHealthcareProvidersMutation({
            variables: {
                ids: [healthcareProvider?.id],
            },
            onCompleted: (data) => {
                const successful =
                    data.issueCredentialsForHealthcareProviders.some(
                        ({ successful }) => successful
                    );

                setSuccess(successful);
                setOpenResultsDialog(successful);
                setLoading(false);
            },
            refetchQueries: ["getIssuedHealthcareProviderExcerptCredentials"],
        });

    const onSubmit = form.handleSubmit(() => {
        setLoading(true);

        issueCrentialForHealthcareProvider();
    });

    const onCloseResultsDialog = () => {
        setOpenResultsDialog(false);
    };

    return (
        <CreateHealthcareProviderExcerptProvider
            value={createHealthcareProviderExcerpt}
        >
            <form onSubmit={onSubmit} data-testid="form">
                <Container>
                    <Stack spacing={3}>
                        <Box>
                            <Typography variant="h4">
                                {t("healthcare-provider-excerpts-create.title")}
                            </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Stack spacing={3}>
                                        <SelectHealthcareProvider />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <StickyContent>
                                        <FieldPaper
                                            title={
                                                <Typography variant="h6">
                                                    {t(
                                                        "healthcare-provider-excerpts-create.general-info"
                                                    )}
                                                </Typography>
                                            }
                                        >
                                            <Box>
                                                <Stack spacing={3}>
                                                    {!healthcareProvider ? (
                                                        <Box>
                                                            <Alert severity="info">
                                                                {t(
                                                                    "healthcare-provider-excerpts-create.fill-in-healthcare-provider"
                                                                )}
                                                            </Alert>
                                                        </Box>
                                                    ) : null}

                                                    <InfoBox />
                                                    <Stack direction="row">
                                                        <Box flex="1 1 auto" />
                                                        <LoadingButton
                                                            role="submit-button"
                                                            loading={loading}
                                                            variant="contained"
                                                            type="submit"
                                                            disabled={
                                                                !form.formState
                                                                    .isValid
                                                            }
                                                        >
                                                            {t(
                                                                "validated-question-create.submit"
                                                            )}
                                                        </LoadingButton>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </FieldPaper>
                                    </StickyContent>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                </Container>
            </form>
            <CreateHealthcareProviderExcerptResultDialog
                open={openResultsDialog}
                onClose={onCloseResultsDialog}
                success={success}
            />
        </CreateHealthcareProviderExcerptProvider>
    );
};

export default CreateHealthcareProviderExcerpt;
