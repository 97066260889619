/**
 * @fileoverview SubjectDidFilter component
 *
 * This component represents the SubjectDidFilter component.
 * It is responsible for filtering subjects based on their DID.
 */

import type { Key } from "react";
import { forwardRef, useMemo } from "react";
import type { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { useGetSubjectDidsQuery } from "@/src/graphql/generated/api/graphql";

/**
 * Reference type for the SubjectDidFilter component.
 * It is a reference to the ref prop of the Select component.
 * This ref is forwarded for interop with react-hook-form.
 */
export type SubjectDidFilterRef = SelectProps["ref"];

export interface SubjectDidFilterProps
    extends Omit<SelectProps, "ref" | "value" | "defaultValue" | "onChange"> {
    value?: Key[];
    defaultValue?: Key[];
    onChange?: (event: { target: { value: Key[] } }) => void;
}

/**
 * Renders the SubjectDidFilter component.
 *
 * This component is responsible for filtering subjects based on their DID.
 *
 * @component
 * @example
 * // Example usage of SubjectDidFilter component
 * <SubjectDidFilter
 *    filter={[]}
 *    onFilter={(filter) => console.log(filter)}
 * />
 *
 * @param {SubjectDidFilterProps} props - The props for the SubjectDidFilter component.
 * @param {SubjectDidFilterRef} ref - The ref for the SubjectDidFilter component.
 * @returns {JSX.Element} The rendered SubjectDidFilter component.
 */
function SubjectDidFilter(
    props: SubjectDidFilterProps,
    ref: SubjectDidFilterRef
) {
    const { data: subjectDids } = useGetSubjectDidsQuery();

    const availableSubjectDids = subjectDids?.getSubjectDids || [];

    function filterIsChecked(id: Key) {
        return props.value?.includes(id);
    }

    const list = useMemo(
        () =>
            availableSubjectDids.map((subjectDid) => {
                return {
                    id: subjectDid.id,
                    name: subjectDid.name,
                    isChecked: filterIsChecked(subjectDid.id),
                };
            }),
        [availableSubjectDids, props.value]
    );

    function getName(id: Key) {
        const subjectDid = availableSubjectDids.find((s) => s.id === id);
        return subjectDid?.name || id;
    }

    function handleOnSelectChange(event: SelectChangeEvent<Key[]>) {
        props.onChange?.({
            target: {
                value: Array.isArray(event.target.value)
                    ? event.target.value
                    : [event.target.value],
            },
        });
    }

    function renderSelectedValues(values: Key[]) {
        return values.map(getName).join(", ");
    }

    return (
        <FormControl fullWidth>
            {/* FIXME: labels for government theme are fubarred */}
            {/* <InputLabel id="subject">Select Subject</InputLabel> */}
            <Select
                labelId="subject"
                data-testid="subject"
                defaultValue={props.defaultValue}
                value={props.value}
                size="small"
                multiple={true}
                renderValue={renderSelectedValues}
                ref={ref}
                {...props}
                onChange={handleOnSelectChange}
            >
                {list.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                        <Checkbox checked={item.isChecked} />
                        <ListItemText primary={item.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default forwardRef<SubjectDidFilterRef, SubjectDidFilterProps>(
    SubjectDidFilter
);
