import { Chip } from "@mui/material";

export const styled = {
    ProfileVersion({ version }: { version: string }) {
        return (
            <Chip
                sx={{
                    ml: 3,
                    fontWeight: "normal",
                }}
                label={version}
                size="medium"
            />
        );
    },
};
