import type { z } from "zod";
import { parameterSchema } from "./parameter.schema";
import { parametersSchema } from "./parameters.schema";
import { dateRangeInputSchema } from "./dateRangeInput.schema";
import { ParameterTypeEnum } from "./ParameterType.enum";

export { ParameterTypeEnum };

export { parameterSchema };

export { parametersSchema };

export type ParameterType = z.infer<typeof parameterSchema>;

export type ParametersType = z.infer<typeof parametersSchema>;

export function safeParseInput(parameter: unknown) {
    if (!(parameter instanceof Object && "type" in parameter)) {
        return parameter;
    }

    let parsed: any;

    switch (parameter.type) {
        case ParameterTypeEnum.DATE_RANGE:
            parsed = dateRangeInputSchema.safeParse(parameter);
            break;
        default:
            return parameter;
    }

    if (!parsed.success) {
        console.error(parameter, parsed.error);
    }

    return parsed.data ?? parameter;
}
