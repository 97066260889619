import { useMemo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import highlightText from "src/lib/highlight/highlight";
import { type HighlightProps } from "./Highlight.types";

const Highlight = ({ text, highlight }: HighlightProps) => {
    const parts = useMemo(() => {
        return highlightText(text, highlight);
    }, [text, highlight]);

    return (
        <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
            {parts.map((part, index) => (
                <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                >
                    {part.text}
                </Box>
            ))}
        </Grid>
    );
};

export default Highlight;
