import { Outlet } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { Icon, Layout } from "@tribe/react-mui-overheid-thema";
import type { DashboardProps } from "./Dashboard.types";
import { useAuth } from "react-oidc-context";

export const Dashboard = ({ children }: DashboardProps) => {
    const { t } = useTranslation("common", {
        keyPrefix: "dashboard",
    });

    const auth = useAuth();

    const handleLogout = () => {
        void auth.signoutRedirect({
            id_token_hint: auth.user?.id_token,
            post_logout_redirect_uri: window.location.origin,
        });
    };

    return (
        <Layout
            data-testid="dashboard"
            appBar={{
                accountMenu: {
                    tooltip: t("menu.account"),
                    items: [
                        {
                            text: t("signout"),
                            onClick: handleLogout,
                            icon: <Icon name="uit-aanknop" />,
                            testId: "logout",
                        },
                    ],
                },
            }}
            mainMenu={{
                items: [
                    [
                        {
                            href: "/validated-questions",
                            text: t("menu.validated-questions"),
                            icon: <Icon name="vraag-en-antwoord" />,
                        },
                        {
                            href: "/healthcare-provider-excerpts",
                            text: t("menu.healthcare-provider-data-summaries"),
                            icon: <Icon name="delen" />,
                        },
                        {
                            href: "/subject-dids",
                            text: t("menu.subject-dids"),
                            icon: <Icon name="verbinding" />,
                        },
                        {
                            href: "/users",
                            text: t("menu.users"),
                            icon: <Icon name="user" />,
                        },
                    ].filter((item) => Object.keys(item).length > 0),
                ],
            }}
        >
            {children ? children : <Outlet />}
        </Layout>
    );
};

export default Dashboard;
