import { z } from "zod";
import { parse } from "date-fns";
import { parameterBaseSchema } from "./parameterBase.schema";
import { ParameterTypeEnum } from "./ParameterType.enum";

/**
 * Coerces a string to a date or undefined
 */
const dateRangeDate = z.coerce
    .string()
    .nullish()
    .transform((s) => s ?? undefined)
    .transform((s) => (s ? parse(s, "yyyy-MM-dd", new Date()) : undefined));

/**
 * Schema for date range parameter
 */
export const dateRangeSchema = parameterBaseSchema.merge(
    z.object({
        type: z.literal(ParameterTypeEnum.DATE_RANGE),
        startMinDate: dateRangeDate,
        startMaxDate: dateRangeDate,
        endMinDate: dateRangeDate,
        endMaxDate: dateRangeDate,
    })
);
