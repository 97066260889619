import { styled } from "@/src/components/templates/SubjectDIDs/SubjectDIDsOverviewTemplate.styles";
import { useTranslation } from "react-i18next";
import { memo, type MouseEvent, useEffect, useState } from "react";
import PaginatedList from "../../organisms/PaginatedList/PaginatedList";
import {
    useDeactivateUserMutation,
    useGetUsersQuery,
} from "@/src/graphql/generated/api/graphql";
import ConfirmDialog from "@/src/components/organisms/ConfirmDialog/ConfirmDialog";

type User = {
    id: string;
    name: string;
    email: string;
};

const UsersOverviewTemplate = () => {
    const { loading, users } = useUsers();
    const [page] = useState(0);
    const count = users?.length;
    const [pageSize] = useState(count);

    const [toDeactivate, setToDeactivate] = useState<User | null>(null);
    const [confirmedDeactivation, setConfirmedDeactivation] =
        useState<User | null>(null);

    const { t } = useTranslation("common", {
        keyPrefix: "users-overview",
    });

    const handleDeleteConfirmClose = ({
        confirmed,
    }: {
        confirmed?: boolean | null;
    }) => {
        if (confirmed) setConfirmedDeactivation(toDeactivate);
        setToDeactivate(null);
    };

    const [deactivateUser] = useDeactivateUserMutation({
        variables: {
            id: confirmedDeactivation?.id ?? "",
        },
        refetchQueries: ["GetUsers"],
        onCompleted: () => {
            setConfirmedDeactivation(null);
        },
        onError: (error) => {
            console.error(error);
            setConfirmedDeactivation(null);
        },
    });

    useEffect(() => {
        if (confirmedDeactivation) {
            deactivateUser().finally();
        }
    }, [confirmedDeactivation]);

    const handleDeactivateClick = (event: MouseEvent<HTMLButtonElement>) => {
        const id = event.currentTarget.dataset.itemId;

        const user = users?.find((item) => item.id == id) ?? null;

        setToDeactivate(user);
    };

    return (
        <>
            <styled.Container>
                <styled.Header
                    title={t("title")}
                    button={{
                        to: "new",
                        label: t("new"),
                    }}
                />
                <styled.Main>
                    <PaginatedList<any>
                        data={users}
                        loading={loading}
                        count={count}
                        page={page}
                        rowsPerPage={pageSize}
                        onDeleteClick={handleDeactivateClick}
                    />
                </styled.Main>
                <ConfirmDialog
                    open={Boolean(toDeactivate !== null)}
                    indicators={[
                        {
                            id: toDeactivate?.id ?? "",
                            name: toDeactivate?.name ?? "",
                        },
                    ]}
                    onClose={handleDeleteConfirmClose}
                    translationPrefix="confirm-dialogs.users"
                />
            </styled.Container>
        </>
    );
};

function useUsers() {
    const { loading, data, refetch } = useGetUsersQuery({
        fetchPolicy: "network-only",
    });

    return {
        loading,
        refetch,
        users: data?.getUsers
            // Only show enabled users on the frontend.
            .filter((user) => user.isEnabled)
            .map((user) => ({
                id: user.id,
                name: `${user.firstName} - ${user.lastName} (${user.email})`,
                email: user.email,
            })),
    };
}

export default memo(UsersOverviewTemplate);
